import React from 'react';
import { Link } from "react-router-dom";
import Slider from 'react-slick';
import './Placed.css'; // Import CSS file for styling if necessary
import one from "../assets/11.jpg"; // Import mobile image
import two from "../assets/22.jpg"; // Import mobile image
import three from "../assets/33.jpg"; // Import mobile image
import four from "../assets/44.jpg"; // Import mobile image
import six from "../assets/66.jpg"; // Import mobile image
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Placed = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section className="team-section bg-smoke space-top space-extra-bottom">
      <div className="container">
        <div className="row text-center justify-content-center wow fadeInUp" data-wow-delay="0.1s">
          <div className="col-xl-6 col-lg-7 col-md-8 col-sm-9">
            <div className="title-area">
              <h2 className="text-3xl pt-4 text-slate-1000 uppercase text-center">
              Our Placed Students
          </h2>
          <h5 className="text-3xl pt-4 text-slate-1000 uppercase text-center">
              Congratulations
          </h5>
            </div>
          </div>
        </div>
        <Slider {...settings}>
          {/* Single Team */}
          <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="team-card">
              <div className="team-img" data-mask-src="assets/img/team/t-mask.png">
                <img
                                  src={one}
                  alt="team"
                />
              </div>
              <button className="icon-btn expand-btn">
                <i className="fas fa-share-alt" />
              </button>
             
            </div>
          </div>
          {/* Repeat for other team members */}
          <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="team-card">
              <div className="team-img" data-mask-src="assets/img/team/t-mask.png">
                <img
                  src={two}
                  alt="team"
                />
              
              </div>
             
            
            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="team-card">
              <div className="team-img" data-mask-src="assets/img/team/t-mask.png">
                <img
                  src={three}
                  alt="team"
                />
               
              </div>
              <button className="icon-btn expand-btn">
                <i className="fas fa-share-alt" />
              </button>
             
            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="team-card">
              <div className="team-img" data-mask-src="assets/img/team/t-mask.png">
                <img
                  src={four}
                  alt="team"
                />
               
              </div>
              <button className="icon-btn expand-btn">
                <i className="fas fa-share-alt" />
              </button>
             
            </div>
          </div>
     
          <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="team-card">
              <div className="team-img" data-mask-src="assets/img/team/t-mask.png">
                <img
                  src={six}
                  alt="team"
                />
              
              </div>
             
              
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default Placed;
