import React from "react";
import Image4 from "../assets/Website4.jpg";
import Image3 from "../assets/Website3.jpg";

const About = () => {
  return (
    <>

<div name="support" className="w-full mt-24">
        <div className="w-full h-[700px] bg-gray-900/90 absolute">
          <img
            className="w-full h-full object-cover mix-blend-overlay"
            src={Image4}
            alt="/"
          />
        </div>

        <div className="max-w-[1240px] mx-auto text-white relative">
          <h2 className="text-3xl pt-4 text-slate-300 uppercase text-center">
            About SEEDEC
          </h2>

          <div name="about" className="w-full my-8">
            <div className="max-w-[1350px] mx-auto">
              <img
                src={Image3}
                alt="About SEEDEC"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
