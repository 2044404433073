import React from "react";

const Policy = () => {
  return (
    <div>
      <div class="container-fluid p-5 bg-primary text-white text-center">
        <h1>Privacy Policy</h1>
        <p>Seedec considers the security and protection of your personal data and information important. Therefore, Seedec operates its website in compliance with applicable laws on data privacy protection and data security.
If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.!</p>
      </div>

      <div class="container mt-5">
        <div class="row">
          <div class="col-sm-4">
            <h3>Collected Data and Purpose of Processing</h3>
            <p>We only collect personal data (e.g. Names, Country, Location, Telephone/ Mobile, Email ID, etc.) with regard to operating our website only when you voluntarily provided this data to us (e.g. through registration, contact inquiries, surveys, etc.) and we are entitled to use or process such data by virtue of permission granted by you on the basis of statutory provision.

As a general rule, we only use such data exclusively for the purpose for which the data was disclosed to us by you, such as to answer your inquiries, grant you access, process your orders, etc.</p>
            
          </div>
          <div class="col-sm-4">
            <h3>Data Sharing</h3>
            <p>For the purpose indicated above, in so far, as you have provided your consent, or when we are legally entitled to do so, we will share your personal data with the subsidiaries of Seedec, wherever required.

These Strategic Business units are located in and outside India, in American, in this regard; the applicability of data secrecy and protection laws may vary. In such cases, Seedec takes measures to ensure an appropriate level of data privacy and protection.

Data is shared only in compliance with the applicable laws and regulations. We do not sell or otherwise market your personal data to third parties.</p>
           
          </div>
          <div class="col-sm-4">
            <h3>Cookies and Web Beacons</h3>
            <p>Like any other website, Seedec uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default Policy;
