import React, { useEffect, useState } from "react";
import emailjs from 'emailjs-com';
import { Link as ScrollLink } from "react-scroll";
import "./Hero.css";
import mobile1 from "../assets/MOBAILESIZE.jpg";
import ImageDesktop from "../assets/Website11.jpg";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Hero = () => {
  const [backgroundImage, setBackgroundImage] = useState(ImageDesktop);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768;
      setBackgroundImage(isMobile ? mobile1 : ImageDesktop);
    };

    handleResize(); // Set initial background image based on window width

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);



  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
    mode: '',
    course: '',
    specialization: '',
    mobileNumber: '',

});

const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
};

const handleSubmit = (e) => {
    e.preventDefault();

    const userId = process.env.REACT_APP_EMAILJS_USER_ID;
    const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;

    // Send the email
    emailjs.send(serviceId, templateId, formData, userId)
        .then((response) => {
            console.log('Email sent successfully:', response);
            setIsPopupOpen(false);
            toast.success('Data sent successfully!');
        })
        .catch((error) => {
            console.error('Error sending email:', error);
        });
};

  return (
    <div
      name="home"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div className="grid md:grid-cols-2 max-w-[1240px] m-auto">
        <div className="flex flex-col justify-center md:items-start w-full px-2 py-8">
          <p className="text-2xl text-white">Welcome to SEEDEC</p>
          <h1 className="py-3 text-5xl font-bold text-white">
            Empowering Engineers
          </h1>
          <p className="text-2xl text-white">
            for Success in the BIM Industry!
          </p>
          <button
            className="text-indigo-600 py-2 px-6 sm:w-[60%] my-4 bg-white text-black"
            onClick={() => setIsPopupOpen(true)}
          >
            Enroll Now
          </button>

          <button className="text-indigo-600 py-2 px-6 sm:w-[60%] my-2 bg-white text-black">
            <ScrollLink to="pricing" smooth={true} offset={-50} duration={500}>
              Explore Courses
            </ScrollLink>
          </button>

          {isPopupOpen && (
            <div className="popup-overlay">
              <div className="popup-content">
                <h2>Enroll Now</h2>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="fullName">Full Name:</label>
                    <input type="text" id="fullName" name="name" value={formData.name} onChange={handleChange} required />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input type="email" id="email" name="email" value={formData.email} onChange={handleChange}  required/>
                  </div>

                  <div className="form-group">
                    <label htmlFor="address">Address:</label>
                    <input type="text" id="address" name="address" value={formData.address} onChange={handleChange}  required />
                  </div>

                  <div className="form-group">
                    <label htmlFor="country"> Mode of Training:</label>
                    <select id="mode" name="mode" value={formData.country} onChange={handleChange}  required>
                      <option value="" disabled selected>
                        Select your Mode
                      </option>
                      <option value="Online">Online</option>
                      <option value="Offline">Offline</option>
                      {/* Add more options as needed */}
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="nation"> Select Course:</label>
                    <select id="course" name="course" value={formData.nation} onChange={handleChange}  required>
                      <option value="" disabled selected>
                        Select your course
                      </option>
                      <option value="mep">MEP DESIGN</option>
                      <option value="elec">ELECTRICAL DESIGN</option>
                      <option value="bim">BIM PROFESSIONAL-MEP</option>
                      <option value="bm">BIM ENGINEER-BIM MEP</option>
                      <option value="civil">CIVIL DESIGN</option>
                      <option value="bciv">BIM PROFESSIONAL-CIVIL</option>
                      {/* Add more options as needed */}
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="specialization">Specialization:</label>
                    <select id="specialization" name="specialization" value={formData.specialization} onChange={handleChange}  required>
                      <option value="" disabled selected>
                        Select your Specialization
                      </option>
                      <option value="Civil">Civil</option>
                      <option value="Mechanical">Mechanical</option>
                      <option value="Electrical">Electrical</option>
                      <option value="Architecture">Architecture</option>
                      {/* Add more options as needed */}
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="mobileNumber">Mobile Number:</label>
                    <input
                      type="tel"
                      id="mobileNumber"
                      name="mobileNumber"
                      value={formData.mobileNumber} onChange={handleChange}
                      required
                    />
                  </div>

                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setIsPopupOpen(false)}
                  >
                    Cancel
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Hero;
