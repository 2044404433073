import React from "react";
import { Link } from 'react-router-dom';
import { FaFacebook, FaLinkedin, FaInstagram, FaWhatsapp  } from "react-icons/fa";

const Footer = () => {
  return (
   <div className="w-full mt-24 bg-blue-900 text-gray-300 py-2 px-2">
  <div className="max-w-[1240px] mx-auto grid grid-cols-2 md:grid-cols-6 border-b-2 border-gray-600 py-8">
    {/* Your content goes here */}
        <div>
          <h6 className="font-bold uppercase pt-2">Our Courses</h6>
          <ul>
            <li className="py-1">BIM</li>
            <li className="py-1">Electricsl</li>
            <li className="py-1">Civil</li>
            <li className="py-1">Mechanical</li>
            <li className="py-1">MEP</li>
          </ul>
        </div>
        <div>
          <h6 className="font-bold uppercase pt-2">Our Support</h6>
          <ul>
            <li className="py-1">Courses</li>
            <li className="py-1">Pricing</li>
            <li className="py-1">Contact</li>
            <li className="py-1">Guides</li>
            <li className="py-1">Placement</li>
          </ul>
        </div>
        <div>
          <h6 className="font-bold uppercase pt-2">Organisation</h6>
          <ul>
            <li className="py-1">About</li>
            <li className="py-1">Blog</li>
            <li className="py-1">Jobs</li>
            <li className="py-1">Press</li>
            <li className="py-1">Partners</li>
          </ul>
        </div>
        <div>
          <h6 className="font-bold uppercase pt-2">Actions</h6>
          <ul>
    <li className='py-1'><Link to='/privacypolicy'>Privacy Policy</Link></li>
    <li className='py-1'><Link to='/support'>Support</Link></li>
    <li className='py-1'><Link to='/service'>Service</Link></li>
    <li className='py-1'><Link to='/RefundPolicy'>Our Refund Policy</Link></li>
</ul>
        </div>
        <div className="col-span-2 pt-8 md:pt-2">
          <p className="font-bold uppercase py-4">
            Subscribe to our newsletter
          </p>
          {/* <p className='py-4'>The latest news, articles, and resources, sent to your inbox weekly.</p> */}
          <form className="flex flex-col sm:flex-row">
            <input
              className="px-4 py-1 w-full p-2 mr-4 rounded-md mb-4"
              type="email"
              placeholder="Enter email.."
            />
            <button className="px-4 py-1 bg-[#6abe54] text-white text-sm">
              Subscribe
            </button>
          </form>
        </div>
      </div>

      <div className="flex flex-col max-w-[1240px] px-2 py-2 mx-auto justify-between sm:flex-row text-center text-gray-500">
      <a href="https://www.infinitigrowit.solutions" target="_blank" className="text-center" style={{color: "white", textDecoration: 'underline'}}>Designed|Developed|Maintened by InfinitiGrowIT Solutions</a>
        <div className="flex justify-between sm:w-[300px] pt-4 text-2xl text-gray-800">
          <a
            href="https://www.facebook.com/profile.php?id=61551540124262&mibextid=9R9pXO"
            className="hover:text-gray-600"
          >
            <FaFacebook />
          </a>
          <a
            href="https://instagram.com/seedec.official?igshid=OGQ5ZDc2ODk2ZA=="
            className="hover:text-gray-600"
          >
            <FaInstagram />
          </a>
          <a
            href="https://www.linkedin.com/company/seedec"
            className="hover:text-gray-600"
          >
            <FaLinkedin />
          </a>
          <a
    href="https://wa.me/9175313802"  // Replace with your WhatsApp number
    className="hover:text-gray-600"
  >
    <FaWhatsapp />
  </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
