import React, { useEffect, useState } from "react";
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactForm = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
    message: '',
    mobileNumber: '',

});

const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
};

const handleSubmit = (e) => {
    e.preventDefault();

    const userId = process.env.REACT_APP_EMAILJS_USER_ID;
    const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;

    // Send the email
    emailjs.send(serviceId, templateId, formData, userId)
        .then((response) => {
            console.log('Email sent successfully:', response);
            setIsPopupOpen(false);
            toast.success('Data sent successfully!');
        })
        .catch((error) => {
            console.error('Error sending email:', error);
        });
};

  return (
    <section style={{
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      width: '90%',
      maxWidth: '550px',
      margin: 'auto',
      marginTop: '2em'
    }} className="contact-form">
      <h1 style={{
        textTransform: 'capitalize',
        fontWeight: 'bold',
        fontSize: '2rem',
        color: '#0076A3',
        marginBottom: '.5em'
      }} className="sectionHeader">contact us</h1>
      <h1 style={{
        marginBottom: '.5em',
        fontWeight: 'bold',
        fontSize: '1em'
      }} className="heading">Get In Touch!</h1>
     

      <div style={{ display: 'grid', gap: '3em' }} className="contactForm">
        <form style={{ width: '100%', marginTop: '0.5em' }} action="#" onSubmit={handleSubmit}>
          <h1 style={{ marginBottom: '.5em', fontWeight: 'bold' }} className="sub-heading">Need Support !</h1>
          <p style={{ textAlign: 'left' }} className="para para2">Contact us for a quote, help to join the them.</p>
          <input style={{
            width: '95%',
            maxWidth: '700px',
            border: 'none',
            fontSize: '.9rem',
            padding: '1em',
            outline: 'none',
            backgroundColor: '#343F4B',
            color: '#74828f',
            borderRadius: '10px',
            border: '1px solid rgb(53, 53, 53)',
            marginBottom: '1em'
          }} type="text" className="input" placeholder="your name" name="name" value={formData.name} onChange={handleChange} required/>
          <input style={{
            width: '95%',
            maxWidth: '700px',
            border: 'none',
            fontSize: '.9rem',
            padding: '1em',
            outline: 'none',
            backgroundColor: '#343F4B',
            color: '#74828f',
            borderRadius: '10px',
            border: '1px solid rgb(53, 53, 53)',
            marginBottom: '1em'
          }} type="text" className="input" placeholder="your email" name="email" value={formData.email} onChange={handleChange} required />
          <input style={{
            width: '95%',
            maxWidth: '700px',
            border: 'none',
            fontSize: '.9rem',
            padding: '1em',
            outline: 'none',
            backgroundColor: '#343F4B',
            color: '#74828f',
            borderRadius: '10px',
            border: '1px solid rgb(53, 53, 53)',
            marginBottom: '1em'
          }} type="text" className="input" placeholder="Mobile" name="mobileNumber" value={formData.mobileNumber} onChange={handleChange} required/>
          <input style={{
            width: '95%',
            maxWidth: '700px',
            border: 'none',
            fontSize: '.9rem',
            padding: '1em',
            outline: 'none',
            backgroundColor: '#343F4B',
            color: '#74828f',
            borderRadius: '10px',
            border: '1px solid rgb(53, 53, 53)',
            marginBottom: '1em'
          }} type="text" className="input" placeholder="your Address" name="address" value={formData.address} onChange={handleChange} required/>
          <textarea style={{
            width: '95%',
            maxWidth: '700px',
            border: 'none',
            fontSize: '.9rem',
            padding: '1em',
            outline: 'none',
            backgroundColor: '#343F4B',
            color: '#74828f',
            borderRadius: '10px',
            border: '1px solid rgb(53, 53, 53)',
            marginBottom: '1em'
          }} className="input" cols="30" rows="5" placeholder="Your message..." name="message" value={formData.message} onChange={handleChange} required></textarea>
          <input style={{
            backgroundColor: '#0076A3',
            border: 'none',
            color: '#fff',
            fontWeight: 'bold',
            cursor: 'pointer',
            width: '100%'
          }} type="submit" className="input submit" />
        </form>

        <div style={{ position: 'relative', width: '100%', height: '500px' }} className="map-container">
          <div style={{
            position: 'absolute',
            // backgroundColor: '#0076A3',
            top: '0',
            right: '0',
            width: '100%',
            height: '90%',
            borderRadius: '20px'
          }} className="mapBg"></div>
          <div style={{
            position: 'absolute',
            bottom: '0',
            left: '0',
            width: '90%',
            height: '90%'
          }} className="map">
            <iframe src="https://maps.google.com/maps?q=1st+Floor%2C+Domino%27s+Complex%2C+Sanghani+%26+Kazi+empire%2C+Near+MIT+College%2CBeed+bypass+road%2C+Ch.Sambhaji+Nagar+%28Aurangabad%29%2C+431005.&t=&z=13&ie=UTF8&iwloc=&output=embed" width="340" height="450" style={{ border: '0' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '2em', textAlign: 'left' }} className="contactMethod">
        <div style={{ display: 'flex', alignItems: 'center' }} className="method">
          <i style={{ fontSize: '2rem', color: '#0076A3', width: '70px' }} className="fa-solid fa-location-dot contactIcon"></i>
          <article style={{}} className="text">
            <h1 style={{ marginBottom: '.5em', fontWeight: 'bold' }} className="sub-heading">Location</h1>
            <p style={{ fontSize: '1.1rem' }} className="para">1st Floor, Domino's Complex, Sanghani & Kazi empire, Near MIT College,Beed bypass road, Ch.Sambhaji Nagar (Aurangabad), 431005.</p>
          </article>
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }} className="method">
          <i style={{ fontSize: '2rem', color: '#0076A3', width: '70px' }} className="fa-solid fa-envelope contactIcon"></i>
          <article style={{}} className="text">
            <h1 style={{ marginBottom: '.5em', fontWeight: 'bold' }} className="sub-heading">Email</h1>
            <p style={{ fontSize: '1.1rem' }} className="para">Email: seedec.official@gmail.com</p>
          </article>
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }} className="method">
          <i style={{ fontSize: '2rem', color: '#0076A3', width: '70px' }} className="fa-solid fa-phone contactIcon"></i>
          <article style={{}} className="text">
            <h1 style={{ marginBottom: '.5em', fontWeight: 'bold' }} className="sub-heading">Phone</h1>
            <p style={{ fontSize: '1.1rem' }} className="para">9175313802</p>
          </article>
        </div>
      </div>
    </section>
  );
}

export default ContactForm;
