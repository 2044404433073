import React, { useEffect, useState } from "react";
import emailjs from 'emailjs-com';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import Image1 from '../assets/c2.jpg';
import Image2 from '../assets/c22.jpg';
import Image3 from '../assets/c1.jpg';
import Image4 from '../assets/c4.jpg';
import Image5 from '../assets/c5.jpg';
import Image6 from '../assets/c6.jpg';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Pricing = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
    mode: '',
    course: '',
    specialization: '',
    mobileNumber: '',

});

const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
};

const handleSubmit = (e) => {
    e.preventDefault();

    const userId = process.env.REACT_APP_EMAILJS_USER_ID;
    const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;

    // Send the email
    emailjs.send(serviceId, templateId, formData, userId)
        .then((response) => {
            console.log('Email sent successfully:', response);
            setIsPopupOpen(false);
            toast.success('Data sent successfully!');
        })
        .catch((error) => {
            console.error('Error sending email:', error);
        });
};


  return (
    <>
    <div name='pricing' className='w-full text-white my-24'>
      <div className='w-full h-[800px] bg-slate-900 absolute mix-blend-overlay'></div>

      <div className='max-w-[1240px] mx-auto text-white relative'>

      <div className='text-center py-1 text-slate-100'>
  <h2 className="text-3xl pt-4 text-slate-300 uppercase text-center text-black">Courses</h2>
        </div>

        <div className='grid md:grid-cols-2'>

        <div className='bg-white text-slate-900 m-4 p-8 rounded-xl shadow-2xl relative'>
      <h1 className='text-2xl py-1 text-center'>ELECTRICAL DESIGN COURSE FOR ELECTRICAL ENGINEER</h1>
      <div>
        <img
          src={Image1}
          alt='Your Alt Text'
          className='w-full h-auto max-w-[100%] max-h-[300px] rounded-xl my-4'
        />
        
      </div>
      {/* <p className='text-2xl py-8 text-slate-500'>Lorem ipsum dolor, sit amet consectetur adipisicing.</p> */}
      <div className='text-1xl'>
        <h4 className='flex py-4'><AiOutlineCheckCircle  className='w-8 mr-5 text-green-600'  />Program Duration: 90 hrs</h4>
        <p className='flex py-4'><AiOutlineCheckCircle  className='w-8 mr-5 text-green-600'  />Course Mode: Online/Offline</p>
        <button className='w-full py-2 my-2 bg-[#6abe54]' onClick={() => setIsPopupOpen(true)}>Get Started</button>
      </div>
    </div>

          <div className='bg-white text-slate-900 m-4 p-8 rounded-xl shadow-2xl relative'>
      <h1 className='text-2xl py-1 text-center'>MEP DESIGN COURSE FOR MECHANICAL ENGINEER</h1>
      <div>
        <img
          src={Image2}
          alt='Your Alt Text'
          className='w-full h-auto max-w-[100%] max-h-[300px] rounded-xl my-4'
        />
        
      </div>
      {/* <p className='text-2xl py-8 text-slate-500'>Lorem ipsum dolor, sit amet consectetur adipisicing.</p> */}
      <div className='text-1xl'>
      <h4 className='flex py-4'><AiOutlineCheckCircle  className='w-8 mr-5 text-green-600'  />Program Duration: 90 hrs</h4>
        <p className='flex py-4'><AiOutlineCheckCircle  className='w-8 mr-5 text-green-600'  />Course Mode: Online/Offline</p>
        <button className='w-full py-2 my-2 bg-[#6abe54]' onClick={() => setIsPopupOpen(true)}>Get Started</button>
      </div>
    </div>

           <div className='bg-white text-slate-900 m-4 p-8 rounded-xl shadow-2xl relative'>
      <h1 className='text-2xl py-1 text-center'>BIM ENGINEER (MEP) COURSE FOR MECHANICAL & ELECTRICAL ENGINEER</h1>
      <div>
        <img
          src={Image3}
          alt='Your Alt Text'
          className='w-full h-auto max-w-[100%] max-h-[300px] rounded-xl my-4'
        />
        
      </div>
      {/* <p className='text-2xl py-8 text-slate-500'>Lorem ipsum dolor, sit amet consectetur adipisicing.</p> */}
      <div className='text-1xl'>
        <h4 className='flex py-4'><AiOutlineCheckCircle  className='w-8 mr-5 text-green-600'  />Program Duration: 120 hrs</h4>
        <p className='flex py-4'><AiOutlineCheckCircle  className='w-8 mr-5 text-green-600'  />Course Mode: Online/Offline</p>
        <button className='w-full py-2 my-2 bg-[#6abe54]' onClick={() => setIsPopupOpen(true)}>Get Started</button>
      </div>
    </div>

          <div className='bg-white text-slate-900 m-4 p-8 rounded-xl shadow-2xl relative'>
      <h1 className='text-2xl py-1 text-center'>BIM PROFESSIONAL (MEP DESIGN + BIM MEP) COURSE FOR MECHANICAL & ELECTRICAL ENGINEER</h1>
      <div>
        <img
          src={Image4}
          alt='Your Alt Text'
          className='w-full h-auto max-w-[100%] max-h-[300px] rounded-xl my-4'
        />
        
      </div>
      {/* <p className='text-2xl py-8 text-slate-500'>Lorem ipsum dolor, sit amet consectetur adipisicing.</p> */}
      <div className='text-1xl'>
        <h4 className='flex py-4'><AiOutlineCheckCircle  className='w-8 mr-5 text-green-600'  />Program Duration: 180 hrs</h4>
        <p className='flex py-4'><AiOutlineCheckCircle  className='w-8 mr-5 text-green-600'  />Course Mode: Online/Offline</p>
        <button className='w-full py-2 my-2 bg-[#6abe54]' onClick={() => setIsPopupOpen(true)}>Get Started</button>
      </div>
    </div>

           <div className='bg-white text-slate-900 m-4 p-8 rounded-xl shadow-2xl relative'>
      <h1 className='text-2xl py-1 text-center'>CIVIL DESIGN COURSE FOR CIVIL ENGINEER</h1>
      <div>
        <img
          src={Image5}
          alt='Your Alt Text'
          className='w-full h-auto max-w-[100%] max-h-[300px] rounded-xl my-4'
        />
        
      </div>
      {/* <p className='text-2xl py-8 text-slate-500'>Lorem ipsum dolor, sit amet consectetur adipisicing.</p> */}
      <div className='text-1xl'>
        <h4 className='flex py-4'><AiOutlineCheckCircle  className='w-8 mr-5 text-green-600'  />Program Duration: 120 hrs</h4>
        <p className='flex py-4'><AiOutlineCheckCircle  className='w-8 mr-5 text-green-600'  />Course Mode: Online/Offline</p>
        <button className='w-full py-2 my-2  bg-[#6abe54]' onClick={() => setIsPopupOpen(true)}>Get Started</button>
      </div>
    </div>

    <div className='bg-white text-slate-900 m-4 p-8 rounded-xl shadow-2xl relative'>
      <h1 className='text-2xl py-1 text-center'>BIM ENGINEER (CIVIL) COURSE FOR CIVIL & ARCHITECT</h1>
      <div>
        <img
          src={Image6}
          alt='Your Alt Text'
          className='w-full h-auto max-w-[100%] max-h-[300px] rounded-xl my-4'
        />
        
      </div>
      {/* <p className='text-2xl py-8 text-slate-500'>Lorem ipsum dolor, sit amet consectetur adipisicing.</p> */}
      <div className='text-1xl'>
        <h4 className='flex py-4'><AiOutlineCheckCircle  className='w-8 mr-5 text-green-600'  />Program Duration: 180 hrs</h4>
        <p className='flex py-4'><AiOutlineCheckCircle  className='w-8 mr-5 text-green-600'  />Course Mode: Online/Offline</p>
        <button className='w-full py-2 my-2 bg-[#6abe54]' onClick={() => setIsPopupOpen(true)}>Get Started</button>
      </div>
    </div>
          
        </div>
      </div>
    </div>

    {isPopupOpen && (
            <div className="popup-overlay">
              <div className="popup-content">
                <h2>Enroll Now</h2>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="fullName">Full Name:</label>
                    <input type="text" id="fullName" name="name" value={formData.name} onChange={handleChange} required />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input type="email" id="email" name="email" value={formData.email} onChange={handleChange}  required/>
                  </div>

                  <div className="form-group">
                    <label htmlFor="address">Address:</label>
                    <input type="text" id="address" name="address" value={formData.address} onChange={handleChange}  required />
                  </div>

                  <div className="form-group">
                    <label htmlFor="country"> Mode of Training:</label>
                    <select id="mode" name="mode" value={formData.country} onChange={handleChange}  required>
                      <option value="" disabled selected>
                        Select your Mode
                      </option>
                      <option value="Online">Online</option>
                      <option value="Offline">Offline</option>
                      {/* Add more options as needed */}
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="nation"> Select Course:</label>
                    <select id="course" name="course" value={formData.nation} onChange={handleChange}  required>
                      <option value="" disabled selected>
                        Select your course
                      </option>
                      <option value="mep">MEP DESIGN COURSE</option>
                      <option value="elec">ELECTRICAL DESIGN COURSE</option>
                      <option value="bim">BIM PROFESSIONAL-MEP COURSE</option>
                      <option value="bm">BIM ENGINEER-MEP DESIGN + BIM MEP COURSE</option>
                      <option value="civil">CIVIL DESIGN COURSE</option>
                      <option value="bciv">BIM PROFESSIONAL-CIVIL COURSE</option>
                      {/* Add more options as needed */}
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="specialization">Specialization:</label>
                    <select id="specialization" name="specialization" value={formData.specialization} onChange={handleChange}  required>
                      <option value="" disabled selected>
                        Select your Specialization
                      </option>
                      <option value="Civil">Civil</option>
                      <option value="Mechanical">Mechanical</option>
                      <option value="Electrical">Electrical</option>
                      <option value="Architecture">Architecture</option>
                      {/* Add more options as needed */}
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="mobileNumber">Mobile Number:</label>
                    <input
                      type="tel"
                      id="mobileNumber"
                      name="mobileNumber"
                      value={formData.mobileNumber} onChange={handleChange}
                      required
                    />
                  </div>

                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setIsPopupOpen(false)}
                  >
                    Cancel
                  </button>
                </form>
              </div>
            </div>
          )}
    </>
  );
};

export default Pricing;


