import React from 'react'

const Service = () => {
  return (
    <div>
      <div>
      <h2>Empower Your Learning Journey with Our Courses</h2>
      <p>
        Welcome to Seedec, where we offer a diverse range of high-quality courses designed to meet your learning needs. Whether you are a beginner looking to explore a new field or an experienced professional aiming to enhance your skills, we have the right courses for you.
      </p>
      <p>
        **Our Courses:**
      </p>
      <ul>
        <li>**Online and Offline Options:** Choose from our flexible learning options, including online courses for those who prefer remote learning and offline classes for a more traditional classroom experience.</li>
        <li>**Expert Instructors:** Learn from industry experts and experienced instructors who are passionate about sharing their knowledge and helping you succeed.</li>
        <li>**Comprehensive Curriculum:** Our courses cover a wide range of topics, providing you with a comprehensive understanding of the subject matter.</li>
        <li>**Hands-On Learning:** Benefit from practical, hands-on learning experiences that allow you to apply your knowledge in real-world scenarios.</li>
        <li>**Certification:** Earn valuable certifications upon course completion, enhancing your resume and validating your skills in the job market.</li>
        <li>**Community Support:** Join a learning community where you can connect with fellow students, share insights, and collaborate on projects.</li>
      </ul>
      <p>
        At Seedec, we are committed to fostering a learning environment that encourages curiosity, innovation, and continuous improvement. Explore our courses today and take the first step towards achieving your learning goals.
      </p>
      <p>
        Have questions or need assistance? Feel free to [contact us] for personalized support.
      </p>
    </div>
    </div>
  )
}

export default Service
