import React, { useState } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import loloDesktop from "../assets/WEBLOGO2.png"; // Import desktop image
import loloMobile from "../assets/WEBLOGO.png"; // Import mobile image
import { FaBars, FaTimes } from "react-icons/fa";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);
  const handleClose = () => setNav(!nav);

  return (
    <div className="w-screen h-[80px] z-10 bg-zinc-200 fixed drop-shadow-lg">
      <div className="px-2 flex justify-between items-center w-full h-full">
        <div className="flex items-center">
          <h1 className="text-2xl font-bold mr-4 sm:text-2xl">
            {/* Conditionally render desktop or mobile image */}
            <a href="/">
              <img
                className="hidden md:block w-68 h-40"
                src={loloDesktop}
                alt="/"
              />{" "}
              {/* Desktop image */}
            </a>

            <a href="/">
              <img className="md:hidden w-32 h-20" src={loloMobile} alt="/" />{" "}
              {/* Mobile image */}
            </a>
          </h1>
          <ul className="hidden md:flex">
            <li>
              <Link to="home" smooth={true} duration={500}>
                Home
              </Link>
            </li>
            <li>
              <Link to="about" smooth={true} offset={-200} duration={500}>
                About
              </Link>
            </li>
            <li>
              <Link to="ourOffering" smooth={true} offset={-50} duration={500}>
                Our Offering
              </Link>
            </li>
            <li>
              <Link to="allInOne" smooth={true} offset={-100} duration={500}>
                Team
              </Link>
            </li>
            <li>
              <Link to="pricing" smooth={true} offset={-50} duration={500}>
                Courses
              </Link>
            </li>
            <li>
              <Link to="testimonial" smooth={true} offset={-50} duration={500}>
                Testimonial
              </Link>
            </li>
          </ul>
        </div>
        <div className="hidden md:flex pr-4">
        <button
  className="px-4 py-2 border-none bg-transparent text-black mr-4 text-sm"
  onClick={() => window.open('https://seedec-learning.vercel.app/', '_blank')}
>
  Online Classes
</button>
          <button className="px-4 py-2 bg-[#6abe54] text-white text-sm">
            Join Us
          </button>
        </div>
        <div className="md:hidden mr-4" onClick={handleClick}>
          {!nav ? <FaBars className="w-5" /> : <FaTimes className="w-5" />}
        </div>
      </div>

      <ul className={!nav ? "hidden" : "absolute bg-zinc-200 w-full px-8"}>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link onClick={handleClose} to="home" smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link
            onClick={handleClose}
            to="about"
            smooth={true}
            offset={-200}
            duration={500}
          >
            About
          </Link>
        </li>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link
            onClick={handleClose}
            to="ourOffering"
            smooth={true}
            offset={-50}
            duration={500}
          >
            Our Offering
          </Link>
        </li>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link
            onClick={handleClose}
            to="platforms"
            smooth={true}
            offset={-100}
            duration={500}
          >
            Team
          </Link>
        </li>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link
            onClick={handleClose}
            to="allInOne"
            smooth={true}
            offset={-50}
            duration={500}
          >
            Courses
          </Link>
        </li>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link
            onClick={handleClose}
            to="testimonial"
            smooth={true}
            offset={-50}
            duration={500}
          >
            Testimonial
          </Link>
        </li>

        <div className="flex flex-col my-4">
        <button
  className="px-4 py-2 border-none bg-transparent text-black mr-4 text-sm"
  onClick={() => window.open('https://seedec-learning.vercel.app/', '_blank')}
>
  Online Classes
</button>
          <button className="px-4 py-2 bg-[#6abe54] text-white text-sm">
            Join Us
          </button>
        </div>
      </ul>
    </div>
  );
};

export default Navbar;
