import React from 'react'

const Support = () => {
  return (
    <div>
      <div>
      <div class="container-fluid p-5 bg-primary text-white text-center">
        <h1>Support</h1>
      </div>

      <div class="container mt-5">
        <div class="row">
          <div class="col-sm-4">
            <h3>Welcome to the Seedec community forums</h3>
            <p>Get support from the community, share ideas and meet others in a community of your preferred language below. (Note: You’ll need to become a member of the community in order to post in the forums.)</p>
           
          </div>
       
        
        </div>
      </div>
    </div>
    </div>
  )
}

export default Support
