import React from 'react';
import {
  BriefcaseIcon,
  ChipIcon,
  SupportIcon,
  StarIcon,
  PhoneIcon,
} from '@heroicons/react/solid'; // Import from '@heroicons/react/solid'
import { ArrowRightIcon } from "@heroicons/react/solid";
import image4 from '../assets/Website4.jpg';

const Support = () => {
  return (
    <div name='ourOffering' className='w-full mt-24'>
      <div className='w-full h-[700px] bg-gray-900/90 absolute'>
        <img className='w-full h-full object-cover mix-blend-overlay' src={image4} alt="/" />
      </div>

      <div className='max-w-[1240px] mx-auto text-white relative'>
        <div className='px-4 py-2'>
        <h2 className="text-3xl pt-4 text-slate-300 uppercase text-center">Our Offerings</h2>
          <h4 className='text-1xl py-1 text-center'>Job-Oriented Training with Real-World Experience</h4>
          <p className='py-1 text-1xl text-slate-300 text-center'>At SEEDEC, students benefit from hands-on experience, industry-experienced faculty, and a curriculum designed to meet evolving industry needs.</p>
        </div>

        <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4 px-16 pt-12 sm:pt-2 text-black'>
          {/* First Row */}
          <div className='bg-white rounded-xl shadow-2xl max-w-[140px] max-h-[150px]'>
            <div className='p-6'>
              <BriefcaseIcon className='w-12 p-2 bg-[#6abe54] text-white rounded-lg mt-[-2rem]' />
              <h3 className='font-bold my-4'>Offline Classroom Training</h3>
            </div>
          </div>

          <div className='bg-white rounded-xl shadow-2xl max-w-[140px] max-h-[150px]'>
            <div className='p-6'>
              <ChipIcon className='w-12 p-2 bg-[#6abe54] text-white rounded-lg mt-[-2rem]' />
              <h3 className='font-bold my-4'>Online Training</h3>
            </div>
          </div>

          <div className='bg-white rounded-xl shadow-2xl max-w-[140px] max-h-[175px]'>
            <div className='p-6'>
              <PhoneIcon className='w-12 p-2 bg-[#6abe54] text-white rounded-lg mt-[-2rem]' />
              <h3 className='font-bold my-0.7'>Internship for Final Year Students</h3>
            </div>
          </div>

          <div className='bg-white rounded-xl shadow-2xl max-w-[140px] max-h-[150px]'>
            <div className='p-6'>
              <ArrowRightIcon className='w-12 p-2 bg-[#6abe54] text-white rounded-lg mt-[-2rem]' />
              <h3 className='font-bold my-4'>100% Job Assistance</h3>
            </div>
          </div>

          <div className='bg-white rounded-xl shadow-2xl max-w-[140px] max-h-[150px]'>
            <div className='p-6'>
              <SupportIcon className='w-12 p-2 bg-[#6abe54] text-white rounded-lg mt-[-2rem]' />
              <h3 className='font-bold my-4'>Training on Real Projects</h3>
            </div>
          </div>

          {/* Second Row */}
          <div className='bg-white rounded-xl shadow-2xl max-w-[140px] max-h-[150px]'>
            <div className='p-6'>
              <ChipIcon className='w-12 p-2 bg-[#6abe54] text-white rounded-lg mt-[-2rem]' />
              <h3 className='font-bold my-4'>Course Completion Certificate</h3>
            </div>
          </div>

          <div className='bg-white rounded-xl shadow-2xl max-w-[140px] max-h-[150px]'>
            <div className='p-6'>
              <PhoneIcon className='w-12 p-2 bg-[#6abe54] text-white rounded-lg mt-[-2rem]' />
              <h3 className='font-bold my-4'>CV/Portfolio Guidance</h3>
            </div>
          </div>

          <div className='bg-white rounded-xl shadow-2xl max-w-[140px] max-h-[150px]'>
            <div className='p-6'>
              <StarIcon className='w-12 p-2 bg-[#6abe54] text-white rounded-lg mt-[-2rem]' />
              <h3 className='font-bold my-4'>Tool Test Preparation</h3>
            </div>
          </div>

          <div className='bg-white rounded-xl shadow-2xl max-w-[140px] max-h-[150px]'>
            <div className='p-6'>
              <PhoneIcon  className='w-12 p-2 bg-[#6abe54] text-white rounded-lg mt-[-2rem]' />
              <h3 className='font-bold my-4'>Mock Interviews</h3>
            </div>
          </div>

          <div className='bg-white rounded-xl shadow-2xl max-w-[140px] max-h-[150px]'>
            <div className='p-6'>
              <ArrowRightIcon  className='w-12 p-2 bg-[#6abe54] text-white rounded-lg mt-[-2rem]' />
              <h3 className='font-bold my-4'>Practical Assignment</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
