import React from 'react'

const Support = () => {
  return (
    <div>
      <div>
      <div class="container-fluid p-5 bg-primary text-white text-center">
        <h1>REFUND POLICY</h1>
        <p>It is agreed between Seedecand students that Seedecreserves the right to cancel/postpone the training due to reasons beyond its control!</p>
      </div>

      <div class="container mt-5">
        <div class="row">
          <div class="col-sm-4">
            <h3>Mode of refund payment </h3>
            <p>Mode of payment of refund will be through our payment gateway service provider (CCAvenue) only.</p>
           
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Support
